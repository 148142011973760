import { parseCookies, setCookie } from "nookies";
import { getCleverTapInstance } from "../../components/hooks/analytics/clevertap/useCleverTapSetup";
import { cleverTapCommonEvents, cleverTapCommonWords, cleverTapEvents } from "./clevertapConstants";
import {
  FnpPageType,
  getBrowserName,
  getFnpPageType,
  getOperatingSystem,
  isMobileDevice,
  parseQueryString,
  removeEmptyNullAndUndefinedFromObject,
} from "../utils/common";

/**
 *
 * @returns {string} according to page type
 */
export const getPageNameForCleverTap = () => {
  switch (getFnpPageType()) {
    case FnpPageType.HOME:
      return cleverTapCommonWords.HOME;
    case FnpPageType.CATEGORY:
      return cleverTapCommonWords.PLP;
    case FnpPageType.CART_PREVIEW:
      return cleverTapCommonWords.CART;
    case FnpPageType.PRODUCT:
      return cleverTapCommonWords.PRODUCT;
    case FnpPageType.PLP_MICRO_SITE:
      return cleverTapCommonWords.MICROSITE;
    case FnpPageType.INFO:
      return cleverTapCommonWords.INFO;
    case FnpPageType.SEARCH:
      return cleverTapCommonWords.SEARCH;
    case FnpPageType.TESTIMONIAL:
      return cleverTapCommonWords.TESTIMONIAL;
    default:
      return "";
  }
};

/**
 * This is a utility method to call clevertap events
 *
 * @param {string} eventName - contains event name
 * @param {object} eventData - contains object to be shared in event
 * @param {string} eventType - contains event type
 */
function callClevertapEvent(eventName, eventData, eventType = cleverTapEvents.common) {
  const { cleverTapWebSession } = parseCookies();
  const isMobile = isMobileDevice();
  const operatingSystem = getOperatingSystem();
  const browserName = getBrowserName();
  const cleverTapInstance = getCleverTapInstance();
  const filteredEventData = removeEmptyNullAndUndefinedFromObject(eventData);
  const cookieTTL = 20 * 60;

  setCookie(null, "cleverTapWebSession", "webSessionStarted", {
    maxAge: cookieTTL,
    path: "/",
  });

  const searchQuery = parseQueryString(window.location.search);
  const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign, gclid } = searchQuery;

  if (!cleverTapWebSession) {
    const payload = {
      Platform: isMobile ? "mobile" : "desktop",
      browser: browserName,
      page_name: eventData?.page_name,
      page_type: eventData?.page_type,
      complete_url: window.location.href,
      clean_url: `${window.location.origin}${window.location.pathname}`,
      operating_system: operatingSystem,
      utm_source_name: utmSource,
      utm_medium_name: utmMedium,
      utm_campaign_name: utmCampaign,
      gclid,
    };

    callClevertapEvent(cleverTapCommonEvents.sessionStart, payload);
  }

  if (cleverTapInstance) {
    try {
      switch (eventType) {
        case cleverTapEvents.common:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
        case cleverTapEvents.userLogin:
          cleverTapInstance.onUserLogin.push(filteredEventData);
          break;
        default:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("CleverTap event push failed:", error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn("CleverTap instance is not initialized.");
  }
}

export default callClevertapEvent;
