import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { parseCookies } from "nookies";
import { useSelector } from "react-redux";
import { INR, INR_CURRENCY_SYMBOL, PLP } from "../../../../common/constants";
import CheckboxLabel from "../checkboxLabel";
import CustomCheckBox from "../../../../common/checkbox/customCheckbox";

const useStyles = makeStyles({
  priceFilterSec: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
});

/**
 * This function renders the price filter section
 *
 * @param {object} props object of props
 * @param {object} props.facetOptions filter options
 * @param {string} props.facetFieldName filter options name
 * @param {object} props.defaultSelection default selected state
 * @param {string} props.facetFieldDisplayName filter  display options name
 * @returns {React.Fragment} return facet price filters.
 */
const PriceFilterList = ({ facetOptions, facetFieldName, defaultSelection, facetFieldDisplayName }) => {
  const cookies = parseCookies();
  const classes = useStyles();
  const { isMobile } = useSelector((state) => state.appConfigs);
  const { geoId } = useSelector((state) => state.productsLists);
  const currencies = useSelector((state) => state.currenciesList.currencies);
  const selectedCurrency = useSelector((state) => state.appData.selectedCurrency) || cookies.localCurrency || "INR";
  const selectedCurrencyFactor =
    (currencies.length && currencies.filter((x) => x.currency.id === selectedCurrency)[0].currency.factor) || 1;
  const currencySymbol = selectedCurrency === INR ? INR_CURRENCY_SYMBOL : selectedCurrency;

  /**
   * this function takes the price list convert the symbol and price range as per the requirement and renders the checkbox
   *
   * @returns {React.Fragment} return facet price filters.
   */
  const renderPriceOptions = () => {
    const updatedList =
      facetOptions?.length &&
      facetOptions.map((filterOption, index) => {
        let { displayName: price } = filterOption;
        const priceFrom = parseFloat(filterOption?.priceFrom.trim()) || 0;
        const priceTo = parseFloat(filterOption?.priceTo.trim()) || 0;
        const defaultPrice = `${currencySymbol} ${(priceFrom * selectedCurrencyFactor).toFixed()} ${
          PLP.TO
        } ${currencySymbol} ${(priceTo * selectedCurrencyFactor).toFixed()}`;

        if (priceFrom === 0) {
          price =
            geoId === "india"
              ? defaultPrice
              : `${PLP.LESS_THAN} ${currencySymbol} ${(priceTo * selectedCurrencyFactor).toFixed()}`;
        } else if (priceTo === 0 || (index === facetOptions?.length - 1 && filterOption?.priceTo.trim() === "*")) {
          price = `${currencySymbol} ${(priceFrom * selectedCurrencyFactor).toFixed()} ${PLP.AND_ABOVE}`;
        } else {
          price = defaultPrice;
        }

        return {
          ...filterOption,
          displayName: price,
        };
      });

    return updatedList.map((priceOptions) => (
      <CustomCheckBox
        key={priceOptions.displayName}
        facetOption={priceOptions}
        facetFieldName={facetFieldName}
        facetFieldDisplayName={facetFieldDisplayName}
        defaultSelection={defaultSelection}
        isMobile={isMobile}
        checkBoxLabel={<CheckboxLabel facetOption={priceOptions} facetFieldName={facetFieldName} isMobile={isMobile} />}
      />
    ));
  };

  return (
    <>
      <div className={classes.priceFilterSec} aria-label="price-list">
        {renderPriceOptions()}
      </div>
    </>
  );
};
PriceFilterList.propTypes = {
  facetOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  facetFieldName: PropTypes.string.isRequired,
  defaultSelection: PropTypes.objectOf(PropTypes.any),
  facetFieldDisplayName: PropTypes.string.isRequired,
};
PriceFilterList.defaultProps = {
  defaultSelection: {},
};
export default PriceFilterList;
