export const textConst = {
  checkout: {
    proceedPay: "Proceed To Pay",
    cartEmpty: "Your Cart is empty",
    startShopping: "Start Shopping Now",
    doesNotHaveToBe: "But it doesn't have to be.",
    cartDoesNotHaveToBeEmpty: "Your Cart is empty but it doesn't have to be",
    thisRequired: "This is required",
    landmark: "Landmark",
    senderName: "Sender Name",
    receiverName: "Receiver Name",
    recipientName: "Recipient's Name",
    doorBell: "Doorbell Name",
    recipientNameRequired: "Name is required",
    recipientAddress: "Recipient's Address",
    recipientAddressRequired: "Recipient's address is required",
    recipientMobile: "Recipient's Mobile",
    recipientMobileRequired: "Recipient's mobile number is required",
    recipientAltMobile: "Recipient's Alt Mobile",
    recipientEmailOptional: "Recipient's Email (optional)",
    useAddress: "Use This Address",
    enterCoupon: "Enter Coupon Code",
    step1Details: "Step 1 details",
    orderDetails: "Order & Delivery Details",
    deliveryOn: "Delivery On",
    selectOccasion: "Select Occasion",
    selectRelation: "Select Relation",
    messageTemplate: "Message Template",
    selectTemplate: "Select Template",
    doNotShowNameOnCard: "Don't show my name on the card",
    expiresIn: "Expires in",
    hurryUp: "Hurry up and place your order quickly.",
    cartExpired: "expired! Click OK to change it. Your entered information will not be removed.",
    expired: "Expired",
    giftCoupons: "Gift Coupons",
    youHave: "You have",
    availDiscount: "to avail discount.",
    couponExpireOn: "Your coupon will expire on",
    noCouponsAvailable: "No Coupons Available",
    invalidCoupon: "Invalid Coupon",
    enterAlternateMobNumber: "Enter alternate mobile number",
    enterAlternateEmail: "Enter alternate email id",
    selectedSlotNotAvailable: "Selected Shipping Method/Time Slot is not available. Change it to continue",
    productExpire: "Delivery Date or Time-Slot expired!",
    productExpireChangeLabel: "Please change it to continue. ",
    productExpireRemoveLabel: "Your entered information will not be removed.",
    changeDateTimeSolt: "Change Date/Time-Slot",
    processingHeader: "We are processing your payment",
    processingContext1: "Please be patient, this process might take some time.",
    processingContext2: "Please do not refresh or close this window.",
    shippingScreen: "MobileShippingView",
    selectDeliveryDate: "Select Delivery Date",
    selectDeliveryDateTime: "Select Delivery Date & Time",
    selectDeliveryTimeSlot: "Select Delivery Time Slot",
    dateTimeInfo: "<Date/Time>",
    dialogDeleteItem: "Are you sure you want to delete this item?",
    dialogDiscardAddress: "Do you want to discard this address?",
    dialogDeleteAddress: "Are you sure you want to delete this address?",
    mobileExpireMinute: "min",
    mobileExpireSecond: "secs",
    desktopExpireMinute: "minute(s)",
    desktopExpireSecond: "seconds",
    deleteThisItem: "Delete this item",
    editQtyDeliveryTime: "Edit gifts quantity and delivery time",
    shippingText: "SPECIAL (SHIPPING) INSTRUCTIONS",
    frequentAddons: {
      add: "Add",
      remove: "Remove",
      addNow: "Add Now",
      frequentlyBoughtAddons: "Frequently Bought Addons",
      addonsSelected: "Addons Selected",
      addonsTotal: "Addons Total",
      noAddonsSelected: "No Addons Selected",
      addOnsSelectedTotal: "Add Ons Selected. Total",
      addOnSelectedTotal: "Add On Selected. Total",
      addons: "Addons",
    },
    senderInfo: {
      weRequireYourMobile: "We require your mobile number to send order related information.",
      mobileNumberIsInvalid: "Mobile number is invalid",
      sendOnBehalfOfSomeone: "Send on behalf of someone else",
      senderNameWillBeShown: `Sender's name will be shown in the Message Card. Order related communication will also be sent to the sender.`,
      senderDetails: "Sender's Details",
      orderCommunicationSentToSender: "Order related communication will also be sent to the sender.",
      orderCommunicationSent: " Order related communication will also be sent on these details.",
      weCouldNotFetchYourMobileNumber:
        "We could not fetch your mobile number, please add it to get order related communication.",
      selectedCountry: "Select Country Code",
      requiredField: "This is required field",
      validEmail: "Please enter valid sender email",
      validPhone: "please enter valid phone",
      validName: "Please enter sender name.",
      senderEmail: "Senders's Email",
      senderName: "Senders's Name",
      enterCity: "Please enter sender city",
      sameAsRecipientCity: "Same as recipient's city",
      senderInfoSaveTooltipMessage: "Save sender details to continue.",
      senderBtnLabel: "All order related notifications will be sent on this Email and Mobile number.",
    },
    tnc: {
      contactlessDelivery: "CONTACTLESS DELIVERY",
      ourDeliveryAgents: `Our delivery agent will leave your package outside the door on a clean surface. (Not applicable on digital products)`,
      byContinuingYouAgree: "By continuing you agree to our",
      tncDisclaimer: `T&C/Disclaimer`,
      termsNConditions: "Terms & Conditions",
    },
    delivery: {
      customizationSaved: "Customization Saved",
      deliverTo: "Deliver to",
      deliverOn: "Delivery on",
      expiry: "Expiry",
      changeArr: `CHANGE`,
      deliveryDate: "Please Select Delivery Date",
      deliveryDateExpired: "Delivery date expired! Please change it to continue.",
    },
    messageCard: {
      closePreview: "Close Preview",
      messageOnCard: "Message On Card",
      selectMessageOnCard: "Select Message on Card",
      selectMessage: "Select Message",
      dearDot: "Dear ...,",
      enterYourMessageHere: "Enter Your Message Here...",
      left: "left",
      from: "From",
      hideMyName: "Hide my name",
      messageCard: "Free Message Card",
      saved: "Saved",
      freeMessageCard: "Free Message Card",
      messageOnCake: "Message on Cake",
      enterMessage: "Enter Message",
      messageEmpty: "Message Cannot Be Empty",
      receiverEmpty: "Receiver Name Cannot Be Empty",
    },
    priceDetails: {
      applyCouponsOffers: "Apply Coupons and Offers",
      enterCouponCode: "Enter coupon code",
      couponCodeApplied: "Coupon code applied",
      haveDiscountCoupon: "Have a Discount Coupon?",
      discount: "Discount",
      chargeExtra: "Charge Extra",
      transitionTimeout: "4000",
      originalAmount: "Original Amount",
      disableMessage: " only valid for INR currency",
      shippingStandardDelivery: "Shipping Standard Delivery",
      adjustment: "Adjustment",
      flat: "Flat ₹",
      charges: "Charges",
      subTotal: "Sub Total",
      grandTotal: "Grand Total",
      couponEmptyError: "Enter Coupon Code",
      infoNote: "Note: Prices may vary as per the exchange rate",
      errorCouponFirstText: "Your coupon code",
      errorCouponSecondText: " is not valid for this order",
      AdjustmentText: "Adjustment",
      service: "Service Charges",
      billingAccountHeader: "Payment via Billing Account",
      noBillingAccount: "Oops! No billing account configured for you.",
      partnerContact: "Please contact partner support for help.",
      insufficientFunds: "Insufficient funds, Please contact customer care.",
      donationHeading: "About CRY (Child Rights and You)",
      donationDescription1:
        "Started in 1979, CRY - Child Rights and You, is the most trusted NGO in india. It has been working towards creating happier and healthier childhoods for over 4 decades",
      donationPoint1: "Child Rights and You, is the most trusted NGO in India",
      donation: "Donation to CRY",
    },
  },
  payment: {
    totalAmountPayable: "Total Amount Payable:",
    totalAmount: "Total Amount:",
    makePayment: "Make Payment",
    offersAvailable: "Offers Available",
    myReminders: "My Reminders",
    youCanCreate:
      "You can create a reminder for upcoming occasion to make it extra special with FnP gifts and experiences",
    profileCompletion: "Order / Profile Completion",
    helpUsKnow: "Help us to know you so that we can serve you better!",
    orderStatus: "Order Status",
    confirmed: "Confirmed",
    processing: "Processing",
    onTheWay: "On the way",
    viewOrder: "View order details",
    continueShopping: "Continue shopping",
    paymentFailed: "Payment failed",
    order: "Order",
    yourPayment: "Your payment for the order could not be completed. Please retry the payment.",
    ifMoney: "If money has been deducted from your card/bank account, it would be refunded in 7-10 business days.",
    retryPayment: "Retry payment",
    rs: "Rs.",
    yourOrder: "Your order",
    isConfirmed: "is confirmed.",
    thankYouForShopping: "Thank you for shopping with us",
    giftReminders: "Gift Reminders",
    selectDateReminder: "Select any date to set reminder",
    enterReminderDetails: "Enter reminder details",
    addNewReminder: "Add new reminder",
    savedReminders: "Saved Reminders",
    weSavedReminder: "We have saved a reminder of this order for you for next year.",
    priceDetails: "Price Details",
    totalProductPrice: "Total Product Price",
    receivedBy: "Received By",
    recipientDetails: "Recipient Details",
    paymentNotConfirmed: "Payment Not Confirmed",
    pleaseTryAgain: "Please try again",
    thankYou: "thank You",
    freeCouponMsg: "Paid via coupon/voucher",
    willReceiveEmail: "You will also be receiving an Email and SMS confirming you order.",
    orderAmount: "Order Amount",
    paymentMethod: "Payment Method",
    subOrder: "SUB ORDER",
    tabbyPay: "Pay in 14 days with Tabby. no fees",
    splitIn4: "Split in 4 payments with Tabby no. fees",
    secure100: "100% Safe and Secure Payments",
    addReminder: "Add Reminder",
    editReminder: "Edit Reminder",
    deleteReminder: "Delete Reminder",
    deleteReminderSubTitle: "Are you sure you want to delete this reminder",
    neverMissOccasion:
      "Never miss an occasion to celebrate. Add a reminder to make it extra special with Ferns n Petals.",
    modifyReminderDetails: "Modify reminder details to help us serve you in the future",
    pleaseEnterOne: "Please enter any one of the above details",
    million6: "6 Million People Trust Us",
    chooseYourCurrency: "Choose your currency",
    pay: "Pay",
    inr: "₹",
    tcApply: "T&C Apply",
    emailTo: "EMAIL To",
    ccTo: "CC To",
    smsTo: "SMS To",
    cartNo: "Cart No",
    customerName: "Customer Name",
    sendPaymentLink: "Send Payment Link",
    motoPayment: "Moto Payment",
    transactionId: "Transaction ID",
    popularBanks: "Popular Banks",
    relationWithRecipient: "Relation with recipient",
    senderCity: "Sender's city",
    amountPayText: "Amount payable using this bank:",
    orderUsingDifferentAccount:
      "You may have created this order using a different account. Please login with the account you placed this order with.",
    mistypedOrderNumber: "You may have mistyped your order number. Please check the order number.",
    linkCopiedBroken: "The link you copied from is probably broken. Please check the order number in the address bar.",
    callCustomerService: "Please call our customer service at",
    getAssistance: "to get assistance.",
    areYouSureYouWantCancel: "Are you sure you want cancel?",
    upi: {
      paymentHeader: "Enter your virtual payment address (VPA)",
      paymentFooter:
        "You will recieve a collect request from Ferns N Petals in the UPI/PSP app you used to create your VPA.",
      vpaError: "VPA ID Required",
      vpaFormatError: "Enter correct virtual payment address.",
      chooseOption: "Choose an option",
      payApp: "Pay using any UPI app",
      scanQR: "Scan QR Code to Pay",
      header: "or Pay instantly using QR Code",
      scanQrDescription: "(Click on Pay button and generate QR code)",
    },
    payPal: {
      saferWayPay: "A safer, faster way to pay",
      whyPayPal: "Why use PayPal?",
      oneAccount: "One account you need, to shop locally and internationally.",
      buyerProtection: "Buyer Protection safeguards all of your eligible transactions.",
      keepEarning: "Keep earning card reward points* when you shop with PayPal.",
      refunded: "Refunded returns cover you return shipping cost.",
      noConversion: "No Conversion - This option is currently available to Indian Paypal Users",
    },
    card: {
      cardNumber: "Card Number",
      nameOnCard: "Name On Card",
      expiryMonth: "Expiry Month",
      expiryYear: "Expiry Year",
      cvv: "CVV",
      saveForFuture: "Save this card for future transactions.",
      enterCvv: "Please enter CVV number",
      invalidCvv: "Invalid CVV",
      enterName: "Please enter your name",
      enterDate: "Please Select expiry month/year",
      inValidDate: "Please select valid expiry month/Year",
      invalidCard: "Please enter correct card number",
      correctCard: "Please enter your card number",
      amexCvv: "A 4 digit code on your card.",
      nonAmexCvv: "A 3 digit code on the back of your card.",
    },
    savedCardRBIGuideLines: {
      saveForFutureRBI: "Save my card as per new RBI guidelines.",
      tokenizationRBI:
        "Tokenization is the safest way to secure your card. Tokenizing the card gives these benefits for all your future transactions",
      safePayment: "100% Safe and Secure Payments",
      easierCheckout: "Faster checkout without the hassle of entering card details every time you shop",
      RBIInfo:
        "As per RBI, all card information will be tokenized & secured with card networks (VISA, Mastercard & Rupay) ensuring your card number is not misused",
    },
    emi: {
      emiWithInterest: "EMI with Interest",
      totalInterest: "Total Interest",
      emiPlan: "EMI Plan",
      principleAmount: "Principle Amount",
      interestRate: "Interest Rate*",
      emiAmount: "EMI Amount",
      totalCost: "Total Cost",
      yourEmiDetails: "Your EMI Details",
      selectEmiOptionToProceed: "Please Select EMI option to proceed",
      allBanks: "All Banks",
      enterCardDetails: "Enter Card Details",
    },
    savedCards: {
      savedCards: "Saved Cards",
      deleteCard: "Are you sure you want to delete this Card?",
    },
    netBanking: {
      serverDownMsg: "is facing some issue, we would advise you to use some other bank or payment method.",
      currencyWarning: "This payment method supports INR currency only",
      selectBank: "Please Select Bank",
    },
    wallet: {
      selectWarning: "Please Select Any Wallet",
      usableBalanceForOrder: "Usable balance for this order is ₹",
      fnpCashTnC: "fnpCash Terms & Conditions",
    },
  },
  address: {
    addDeliveryAddress: "Add Delivery Address",
    addNew: "add new",
    address: "address",
    pinCode: "Pin Code",
    recipientCity: "Recipient's City",
    addressType: "Address type",
    giftDeliverOfficeHours: "Gift will be delivered in general office hours.",
    saveDeliverHere: "Save and Deliver here",
    saveInfo: "Save address to continue.",
    addNewAddress: "Add New Address",
    selectDeliveryAddress: "Select Delivery Address",
    viewAllAddress: "View All Addresses",
    showLess: "Show Less",
    recipientCountry: "Recipient's Country",
    recipientAltMobile: "Recipient's Alt. Mobile",
    recipientEmail: "Recipient's Email",
    invalidPhoneNumber: "Phone number is invalid",
    invalidEmail: "Email ID is invalid",
    streetNo: "Street No. and Name",
    aptSuiteHouseNo: "Apt/Suite/House No.",
    houseNumber: "House Number",
    zipcode: "Zipcode",
    RecipientEmailOptional: "Recipient's Email (Optional)",
    doorBell: "Doorbell Name",
    formErrorMessage: "Please fill mandatory (*) fields",
    formSaveMessage: "Please save address details",
    selectAddressLabel: "Select this address",
    editAddressLabel: "Edit this Address",
    deleteAddressLabel: "Delete this Address",
  },
  common: {
    login: "Login",
    home: "Home",
    office: "Office",
    other: "Other",
    apply: "Apply",
    gifts: "Gifts",
    payments: "Payment Options",
    dear: "Dear",
    ok: "Ok",
    or: "Or",
    view: "View",
    save: "Save",
    hide: "Hide",
    message: "Message",
    international: "INTERNATIONAL",
    details: "Details",
    cancel: "Cancel",
    discard: "Discard",
    preview: "Preview",
    continue: "Continue",
    needHelp: "Need Help",
    viewAll: "View All",
    edit: "Edit",
    delete: "Delete",
    shipping: "Shipping",
    total: "Total",
    free: "Free",
    delivery: "Delivery",
    name: "Name",
    occasion: "Occasion",
    date: "Date",
    viewMore: "View more",
    submit: "Submit",
    mobile: "Mobile",
    change: "Change",
    alternateMobile: "Alternate Mobile",
    AvailableBalance: "Available Balance",
    YouUsed: "You used",
    noAdditionalAmountToPay: "Yay! No Additional Amount to Pay",
    youHaveNoOutstandingAmountToPay: "You have no outstanding amount to pay.",
    click: "Click",
    placeOrder: "Place Order",
    buttonToPlaceThisOrder: "button to place this order.",
    website: "website",
    useCode: "Use Code:",
    hrs: "Hrs",
    min: "Min",
    sec: "Sec",
    codeCopied: "Code Copied",
    blackColor: "#000000",
    plpBanner: "PLP Banner",
    discount: "Discount",
  },
  auth: {
    loginToContinue: "Login to continue",
    loginWithOTP: "Login with OTP",
    enterOTPToContinue: "just enter OTP to continue",
    continueToReg: "Continue to Registration",
    loginWithGoogle: "Sign in with Google",
    loginWithFB: "Login with Facebook",
    googleBtnLabel: "Login using your google account",
    fbBtnLabel: "Login using your facebook account",
  },
};

export const checkoutUrls = {
  social: {
    google: "https:/",
  },
};
