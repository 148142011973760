import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  DeliveryLocationInfo,
  DeliveryShippingInfo,
  MultiOrderShippingInfo,
  ProceedToAddOn,
  OrderItem,
} from "./components";
import CheckoutDelivery from "../../checkout/checkout-delivery";
import CheckoutInternationalDeliver from "../../checkout/checkout-international-deliver";
import { convertPrice } from "../../../src/utils/common";
import { updateCartItemValues } from "../../../src/actions/checkout-actions";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import CheckoutDeliveryChangeDesktop from "../../checkout/checkout-delivery-change-desktop";
import { textConst } from "../../../const/text-english";
import CheckoutInternationalDeliveryChangeDesktop from "../../checkout/checkout-international-delivery-change-desktop";

const useStyles = makeStyles({
  viewCart: { paddingBottom: 5 },
  productPanel: {
    borderRadius: 3,
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,.3)",
    marginBottom: 15,
    position: "relative",
    padding: 10,
    paddingBottom: 20,
  },
  desktopViewCart: {
    paddingBottom: 0,
  },
});

/**
 * Component that render items in cart.
 *
 * @param {*} param0 all the props passed to the component.
 * @param {object} param0.cartData data for displaying cart.
 * @param {boolean} param0.currencies To identify whether component is used for cart screen.
 * @param {boolean} param0.isMultiImageUploadOn To identify whether multi image upload is on or not.
 * @param {string} param0.localCurrency stores value of local currency.
 * @returns {React.ReactElement} jsx for the cart listing component.
 */
const CartListing = ({
  cartData,
  currencies,
  isMultiImageUploadOnVal,
  localCurrency,
  updateQuantityAction,
  deleteOrderItemAction,
  handleProceedToAddon,
  isMobile,
  cleverTapCartCommonData,
  ...props
}) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  let isInternationalProductExist = null;
  cartData?.items?.forEach((order) => {
    if (order?.mainProduct?.primaryProductCategoryId === textConst.common.international) {
      isInternationalProductExist = true;
    }
  });
  const [enableCheckoutDomesticCalendar, setEnableCheckoutDomesticCalendar] = useState(false);
  const [enableCheckoutIntlCalendar, setEnableCheckoutIntlCalendar] = useState(false);

  const [selectedGiftIndex, setSelectedGiftIndex] = useState("");
  const isInternationalEnable = useSelector((state) => state.productDetail?.isInternationalEnable);

  useEffect(() => {
    dispatch({ type: APP_CONSTANTS.CLEAR_ADDON_PRODUCT_LIST });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInternationalProductExist) {
      dispatch({ type: APP_CONSTANTS.GET_IS_INTERNATIONAL_ENABLE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternationalProductExist]);

  /**
   *This method updates the cart item object
   *
   * @param {object} data item details like date ,slotId
   */
  const updateShippingDetailsToCartItem = async (data) => {
    await updateCartItemValues(data);
    dispatch({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
  };

  /**
   * This method is used to convert price as per the currency code and factor
   *
   * @param {number}  price product price.
   * @returns {number}  calculated price.
   */
  const getPrice = (price) => {
    if (localCurrency === "INR") {
      return price;
    }
    return convertPrice(currencies, localCurrency, price)?.price;
  };
  return (
    <>
      <div id="viewCart" className={isMobile ? classes.viewCart : classes.desktopViewCart}>
        {cartData?.items?.map((item) => {
          const giftItem = { ...item, mainproduct: item.mainProduct };
          return (
            <div key={item.productId} className={classes.productPanel}>
              {enableCheckoutDomesticCalendar &&
                item.giftIndex === selectedGiftIndex &&
                (isMobile ? (
                  <CheckoutDelivery
                    onSelect={updateShippingDetailsToCartItem}
                    giftItem={giftItem}
                    getPrice={getPrice}
                    onClose={() => {
                      setEnableCheckoutDomesticCalendar(false);
                      setSelectedGiftIndex("");
                    }}
                    cleverTapCartCommonData={cleverTapCartCommonData}
                    triggeredFromCart
                  />
                ) : (
                  <CheckoutDeliveryChangeDesktop
                    onSelect={updateShippingDetailsToCartItem}
                    giftItem={giftItem}
                    getPrice={getPrice}
                    onClose={() => {
                      setEnableCheckoutDomesticCalendar(false);
                      setSelectedGiftIndex("");
                    }}
                    cleverTapCartCommonData={cleverTapCartCommonData}
                    triggeredFromCart
                  />
                ))}
              {isInternationalEnable === "N" &&
                enableCheckoutIntlCalendar &&
                item.giftIndex === selectedGiftIndex &&
                (isMobile ? (
                  <CheckoutInternationalDeliver
                    onSelect={updateShippingDetailsToCartItem}
                    giftItem={giftItem}
                    getPrice={getPrice}
                    onClose={() => {
                      setEnableCheckoutIntlCalendar(false);
                      setSelectedGiftIndex("");
                    }}
                    cleverTapCartCommonData={cleverTapCartCommonData}
                    triggeredFromCart
                  />
                ) : (
                  <CheckoutInternationalDeliveryChangeDesktop
                    onSelect={updateShippingDetailsToCartItem}
                    giftItem={giftItem}
                    getPrice={getPrice}
                    onClose={() => {
                      setEnableCheckoutIntlCalendar(false);
                      setSelectedGiftIndex("");
                    }}
                    cleverTapCartCommonData={cleverTapCartCommonData}
                    triggeredFromCart
                  />
                ))}
              <OrderItem
                giftItem={item}
                currency={localCurrency}
                currencies={currencies}
                isCart
                isMobile={isMobile}
                isMultiImageUploadOn={isMultiImageUploadOnVal}
                updateQuantityAction={updateQuantityAction}
                deleteOrderItemAction={deleteOrderItemAction}
              />
              {item.isAddonsConfigured && (
                <ProceedToAddOn
                  proceedToAddonsPage={() => {
                    handleProceedToAddon(item);
                  }}
                  giftItem={item}
                />
              )}
              {item.showPincodeOrCity && <DeliveryLocationInfo deliveryLocation={item.deliveryLocation} />}
              {item.isMultiOrderProduct ? (
                <MultiOrderShippingInfo
                  shippingDetails={item.shippingDetails}
                  selectedCurrency={localCurrency}
                  currencies={currencies}
                />
              ) : (
                item.shippingDetails?.deliveryDate &&
                item.shippingDetails?.deliveryDate !== "_NA_" && (
                  <DeliveryShippingInfo
                    isMobile={isMobile}
                    timeSlotChangeMsg={item.timeSlotChangeMsg}
                    shippingDetails={item.shippingDetails}
                    selectedCurrency={localCurrency}
                    currencies={currencies}
                    giftIndex={item.giftIndex}
                    postalCode={item.pincode}
                    setEnableCheckoutDomesticCalendar={setEnableCheckoutDomesticCalendar}
                    setEnableCheckoutIntlCalendar={setEnableCheckoutIntlCalendar}
                    setSelectedGiftIndex={setSelectedGiftIndex}
                    mainproduct={item.mainProduct}
                    giftItem={giftItem}
                    cleverTapCartCommonData={cleverTapCartCommonData}
                  />
                )
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const propTypes = {
  cartData: PropTypes.objectOf(PropTypes.any).isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMultiImageUploadOnVal: PropTypes.bool.isRequired,
  localCurrency: PropTypes.string.isRequired,
  updateQuantityAction: PropTypes.func.isRequired,
  deleteOrderItemAction: PropTypes.func.isRequired,
  handleProceedToAddon: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  cleverTapCartCommonData: PropTypes.objectOf(PropTypes.any).isRequired,
};

CartListing.propTypes = propTypes;
export default CartListing;
